import React from 'react';
import { useRef} from 'react';

export default function Start({ setUserName }) {
    const inputRef = useRef();

    const handleClick = () => {
        inputRef.current.value && setUserName(inputRef.current.value)
    }
  return (
    <div className="start">
        <input 
        placeholder='enter your name' 
        className='startInput'
        ref={inputRef}
        />
        <button onClick={handleClick} className='startButton'>Start</button>
    </div>
  )
}

